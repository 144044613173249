import React from 'react'
import Carousel from "react-bootstrap/Carousel";
import "../assets/css/smartSecurity.css";
import houseIcon from "../assets/img/house-icon.png";
import panelIcon from "../assets/img/panel-icon.png";
import appIcon from "../assets/img/app-icon.png";
import cameraIcon  from "../assets/img/camera-icon.png";

const AppSmartSecurity = () => {
  return (
    <div className='smart-security'>
      <div className='smart-security-content'>
        {window.innerWidth <= 1001 ? (
          <div>
            <Carousel>
              <Carousel.Item>
                <div className='smart-security-house-position'>
                  <img
                      src={houseIcon}
                      alt='House Icon'
                      className='house-icon-image'
                  />
                    <h1>
                    ALARME MONITORADO
                    </h1>
                    <h3>
                    De sensores em portas, janelas e de movimento, monitoramento 24 horas por dia, 7 dias por semana. <br/>Seja avisado em caso de invasão ou solicite ajuda em caso de perigo.
                    </h3>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className='smart-security-house-position'>
                  <img
                      src={panelIcon}
                      alt='Panel Icon'
                      className='house-icon-image'
                  />
                    <h1>
                    AUTOMAÇÃO RESIDENCIAL
                    </h1>
                    <h3>
                    Não apenas monitore sua casa, mas também faça o gerenciamento de onde estiver com um sistema de automação inteligente, você pode controlar suas luzes, equipamentos, temperatura do ar-condicionado, tudo à distância.
                    </h3>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className='smart-security-house-position'>
                  <img
                      src={cameraIcon}
                      alt='Camera Icon'
                      className='camera-icon-image'
                  />
                    <h1>
                    CÂMERAS DE SEGURANÇA
                    </h1>
                    <h3>
                    Fique de olho em tudo o que acontece em seu imóvel, mesmo quando estiver longe ou emambientes escuros, com nossas câmeras inteligentes.
                    </h3>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                
              </Carousel.Item>
              <Carousel.Item>
                <div className='smart-security-house-position'>
                  <img
                      src={appIcon}
                      alt='App Icon'
                      className='app-icon-image'
                  />
                    <h1>
                    ADT SMART SECURITY
                    </h1>
                    <h3>
                    Administração das funcionalidades dos dispositivos de alarme, câmeras e automação em um único aplicativo, com recursos inteligentes e intuitivospara você controlar seu sistema na palma de sua mão.
                    </h3>
                    <br/>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        ):(
          <div className='smart-security-content'>
            <div className='smart-security-house-position'>
              <img
                  src={houseIcon}
                  alt='House Icon'
                  className='house-icon-image'
              />
              <h1>
              ALARME MONITORADO
              </h1>
              <h3>
              De sensores em portas, janelas e de movimento, monitoramento 24 horas por dia, 7 dias por semana. <br/>Seja avisado em caso de invasão ou solicite ajuda em caso de perigo.
              </h3>
            </div>
            <div className='smart-security-house-position'>
              <img
                  src={panelIcon}
                  alt='Panel Icon'
                  className='panel-icon-image'
              />
                <h1>
                AUTOMAÇÃO RESIDENCIAL
                </h1>
                <h3>
                Não apenas monitore sua casa, mas também faça o gerenciamento de onde estiver com um sistema de automação inteligente, você pode controlar suas luzes, equipamentos, temperatura do ar-condicionado, tudo à distância.
                </h3>
            </div>
            <div className='smart-security-house-position'>
              <img
                  src={cameraIcon}
                  alt='Camera Icon'
                  className='camera-icon-image'
              />
                <h1>
                CÂMERAS DE SEGURANÇA
                </h1>
                <h3>
                Fique de olho em tudo o que acontece em seu imóvel, mesmo quando estiver longe ou emambientes escuros, com nossas câmeras inteligentes.
                </h3>
            </div>
            <div className='smart-security-house-position'>
              <img
                  src={appIcon}
                  alt='App Icon'
                  className='app-icon-image'
              />
                <h1>
                ADT SMART SECURITY
                </h1>
                <h3>
                Administração das funcionalidades dos dispositivos de alarme, câmeras e automação em um único aplicativo, com recursos inteligentes e intuitivospara você controlar seu sistema na palma de sua mão.
                </h3>
            </div>
          </div>
          
        )}
        
      </div>
    </div>
  )
}

export default AppSmartSecurity